import styled from '@emotion/styled';
import { Contact } from 'components/atoms/contact/';
import Img from 'components/atoms/img/';
import { JaHeading } from 'components/atoms/jaHeading/';
import { List } from 'components/atoms/list/';
import { Text } from 'components/atoms/text/';
import { TextButton } from 'components/atoms/textButton/';
import { Video } from 'components/atoms/video/';
import { Youtube } from 'components/molecules/youtube';
import { Information } from 'components/molecules/information/';
import { TextSet } from 'components/molecules/textSet/';
import { TopicsCard } from 'components/molecules/topicsCard/';
import { TopicsDetailColumn, TopicsDetailColumnExplanation } from 'components/molecules/topicsDetailColumn/';
import { TopicsDetailData } from 'components/molecules/topicsDetailData/';
import { TopicsListWrap } from 'components/molecules/topicsListWrap/';
import { TopicsSectionWrap } from 'components/molecules/topicsSectionWrap/';
import { ContentInner } from 'components/organisms/contentInner/';
import { ContentWrapper } from 'components/organisms/contentWrapper/';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import RangeSlide from 'components/organisms/rangeSlide/';
import * as React from 'react';
import colors from 'style/colors';
import sizes from 'style/sizes';
import { headerHeightPc, headerHeightSp, mq, baseTransitionSecond } from 'style/variables';
import { TopicsDetailEnContext } from '../../gatsby-node/createTopicsDetailEnPages';
import { TopicsDetailEnQuery } from 'types/graphql-types';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import SEO from 'util/seo';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  pageContext: TopicsDetailEnContext;
  data: TopicsDetailEnQuery;
}

const TopicsWrap = styled.div`
  ${mq.onlypc} {
    margin-top: ${headerHeightPc}px;
    padding-top: 101px;
  }
  ${mq.onlysp} {
    margin-top: ${headerHeightSp + 22}px;
  }
`;

const TopicsHead = styled.div`
  ${mq.onlysp} {
    padding: 0 32px;
  }
`;

const TopicsTop = styled.div`
  ${mq.onlysp} {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: ${sizes.margin[81]};
    margin-bottom: ${sizes.margin[100]};
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 32px;
      right: 32px;
      height: 1px;
      background: #d8d8d8;
      opacity: 0.6;
    }
  }
`;
const ContentWrapperGroup = styled.div``;
const TopicsMv = styled.div`
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[180]};
    margin-top: ${sizes.margin[81]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[49]};
  }
`;

const TopicsTextSet = styled(TextSet)`
  & > * {
    & + h3 {
      margin-top: ${sizes.margin[81]};
      ${mq.onlysp} {
        margin-top: ${sizes.margin[64]};
      }
    }
  }

  a {
    text-decoration: underline;
    transition: opacity ${baseTransitionSecond}s ease;
    ${mq.onlypc} {
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

const TopicsOtherWrap = styled.div`
  padding-top: ${sizes.margin[180]};
  padding-bottom: 158px; // 本来180pxだが、TopicCard自体がクリッカブルエリア確保のためにpadding-bottom: 22px確保しているため差し引き158px
  margin-top: ${sizes.margin[180]};
  background-color: ${colors.gray03};
  ${mq.onlysp} {
    padding-top: ${sizes.margin[100]};
    padding-bottom: ${sizes.margin[100]};
    margin-top: ${sizes.margin[100]};
    margin-bottom: ${sizes.margin[64]};
  }
`;

const TopicsBackToWrapTop = styled.div`
  ${mq.onlypc} {
    border-bottom: 1px solid ${colors.gray02};
    padding-bottom: ${sizes.margin[32]};
    margin-bottom: 76px;
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[17]};
  }
`;

const TopicsBackToWrapBottom = styled.div`
  margin-top: ${sizes.margin[81]};
  margin-bottom: ${sizes.margin[81]};
  display: flex;
  justify-content: center;
  ${mq.onlysp} {
    margin-top: ${sizes.margin[49]};
    margin-bottom: ${sizes.margin[49]};
  }
`;

const Pages: React.FC<IndexPageProps> = ({ pageContext, data }) => {
  const acf = data.shifterTopicsEnData?.acf;
  const title = data.shifterTopicsEnData?.title;
  const otherTopics = data.allShifterTopicsEnData.edges;

  return (
    <>
      <SEO title={`${title?.rendered}｜Topics`} en />
      <TopicsWrap>
        <ContentWrapper>
          <TopicsBackToWrapTop>
            <TextButton arrowDirection={'left'} to={'/en/topics'} size={'small'}>
              Back to Index
            </TextButton>
          </TopicsBackToWrapTop>
        </ContentWrapper>
      </TopicsWrap>
      <ContentWrapperGroup>
        <ContentWrapper noSpPadding>
          <TopicsTop>
            <TopicsHead>
              <TopicsDetailData
                category={acf?.category || ''}
                data={dayjs(acf?.launch || undefined).format('YYYY.MM.DD')}
              />
              <JaHeading size={'middle'} dangerouslySetInnerHTML={{ __html: title?.rendered || 'no title' }} />
            </TopicsHead>

            {acf?.kv && (
              <TopicsMv>
                <Img filename={acf?.kv || ''} alt={''} />
              </TopicsMv>
            )}
          </TopicsTop>
        </ContentWrapper>

        {acf?.modules?.map((module, idx) => {
          if (module?.type === 'text_set' && module?.text_set) {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsSectionWrap>
                  <ContentInner>
                    <TopicsTextSet>
                      {module.text_set.map(item => (
                        <React.Fragment key={`module-text-set${idx}`}>
                          {item?.headline1 && (
                            <JaHeading size={'middle'} dangerouslySetInnerHTML={{ __html: item?.headline1 }} />
                          )}
                          {item?.headline2 && (
                            <JaHeading size={'small'} dangerouslySetInnerHTML={{ __html: item?.headline2 }} />
                          )}
                          {item?.headline3 && (
                            <JaHeading size={'xsmall'} dangerouslySetInnerHTML={{ __html: item?.headline3 }} />
                          )}
                          {item?.text && <Text isDiv dangerouslySetInnerHTML={{ __html: item?.text }} />}
                          {item?.ul && (
                            <TopicsListWrap>
                              <List txts={item.ul?.map(listItem => listItem?.li) as string[]}></List>
                            </TopicsListWrap>
                          )}
                          {item?.ol && (
                            <TopicsListWrap>
                              <List ordered txts={item.ol?.map(listItem => listItem?.li) as string[]}></List>
                            </TopicsListWrap>
                          )}
                        </React.Fragment>
                      ))}
                    </TopicsTextSet>
                  </ContentInner>
                </TopicsSectionWrap>
              </ContentWrapper>
            );
          }

          if (module?.type === 'youtube') {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsSectionWrap>
                  {module.youtube?.size === 'large' ? (
                    <Youtube id={module?.youtube.alternative_id || 'V86xKCqVsx0'} />
                  ) : module.youtube?.size === 'small' ? (
                    <ContentInner>
                      <Youtube id={module?.youtube.alternative_id || 'V86xKCqVsx0'} />
                    </ContentInner>
                  ) : null}
                </TopicsSectionWrap>
              </ContentWrapper>
            );
          }

          if (module?.type === 'visual_set') {
            if (module.visual_set?.size === 'large') {
              return (
                <ContentWrapper key={`module-${idx}`} isTopicsVisual>
                  <TopicsSectionWrap>
                    {module?.visual_set?.visual?.includes('.mp4') || module?.visual_set?.visual?.includes('.mov') ? (
                      <Video
                        url={
                          module?.visual_set.visual ||
                          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                        }
                      />
                    ) : (
                      <Img
                        filename={module?.visual_set.visual || ''}
                        alt={''}
                        caption={module?.visual_set.caption || undefined}
                      />
                    )}
                  </TopicsSectionWrap>
                </ContentWrapper>
              );
            }
            if (module.visual_set?.size === 'small') {
              return (
                <ContentWrapper key={`module-${idx}`} isTopicsVisual>
                  <TopicsSectionWrap>
                    <ContentInner>
                      {module?.visual_set?.visual?.includes('.mp4') || module?.visual_set?.visual?.includes('.mov') ? (
                        <Video
                          url={
                            module?.visual_set.visual ||
                            'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                          }
                        />
                      ) : (
                        <Img
                          filename={module?.visual_set.visual || ''}
                          alt={''}
                          caption={module?.visual_set.caption || undefined}
                        />
                      )}
                    </ContentInner>
                  </TopicsSectionWrap>
                </ContentWrapper>
              );
            }
          }

          if (module?.type === 'block' && (module?.block?.sections || module?.block?.title)) {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <TopicsDetailColumn headline={module.block.title || ''} text={module.block.text || ''}>
                  {module.block?.sections && module.block?.sections.length > 0 && (
                    <>
                      {module.block.sections.map((section, idx) => (
                        <TopicsDetailColumnExplanation
                          key={`module-block-${idx}`}
                          imgpath={section?.pic?.url || ''}
                          imgalt={''}
                          headline={section?.headline || 'No headline'}
                          text={section?.text || ''}
                        />
                      ))}
                    </>
                  )}
                </TopicsDetailColumn>
              </ContentWrapper>
            );
          }

          if (module?.type === 'info' && module?.info?.text) {
            return (
              <ContentWrapper key={`module-${idx}`}>
                <Information>
                  <Text isDiv dangerouslySetInnerHTML={{ __html: module.info?.text || 'No text' }} />
                  <Contact address={module.info?.contact || ''} margin={true} />
                </Information>
              </ContentWrapper>
            );
          }
        })}
      </ContentWrapperGroup>
      {otherTopics?.length > 0 && (
        <TopicsOtherWrap>
          <RangeSlide perView={3} title={'Other Topics'}>
            {otherTopics.map((topics, idx) => (
              <TopicsCard
                key={idx}
                title={topics.node.title?.rendered || 'no title'}
                date={dayjs(topics.node.acf?.launch || undefined).format('YYYY.MM.DD')}
                to={`/en/topics/${topics.node.slug}`}
                imgpath={topics.node.acf?.thumb ? topics.node.acf?.thumb : 'noimage/img-card-thumb.jpg'}
                category={topics.node.acf?.category || ''}
                isEn
              />
            ))}
          </RangeSlide>
        </TopicsOtherWrap>
      )}

      <ContentWrapper>
        <TopicsBackToWrapBottom>
          <TextButton arrowDirection={'left'} to={'/en/topics'}>
            Back to Index
          </TextButton>
        </TopicsBackToWrapBottom>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query TopicsDetailEn($slug: String!, $category: String!) {
    site {
      siteMetadata {
        title
      }
    }
    shifterTopicsEnData(slug: { eq: $slug }) {
      acf {
        kv
        category
        thumb
        launch
        modules {
          type
          block {
            sections {
              headline
              pic {
                type
                url
              }
              text
            }
            title
            text
          }
          info {
            text
            contact
          }
          text_set {
            ol {
              li
            }
            ul {
              li
            }
            headline1
            headline2
            headline3
            text
            type
          }
          youtube {
            size
            alternative_id
          }
          visual_set {
            caption
            size
            visual
          }
        }
      }
      title {
        rendered
      }
    }
    allShifterTopicsEnData(
      sort: { fields: acf___launch, order: DESC }
      filter: {
        title: { rendered: { nin: ["dummy", null] } }
        slug: { ne: $slug }
        acf: { category: { eq: $category } }
      }
      limit: 12
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          acf {
            category
            thumb
            launch
          }
          slug
        }
      }
    }
  }
`;
